<div class="logo">
  <img
    class="image responsive"
    src="/assets/mk-logo.svg"
    alt="Logo Elzab"
  >
  <ui-badge
    class="badge"
    [data]="{text: 'Eparagon'}"
  >
  </ui-badge>
</div>