import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'ui-badge',
  templateUrl: './ui-badge.component.html',
  styleUrls: ['./ui-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UiBadgeComponent {

  @Input() data: Badge = { text: 'Online', status: BadgeStatus.DEFAULT };

}

export interface Badge {
  text: string,
  status?: BadgeStatus;
}

export enum BadgeStatus {
  ONLINE = 'online',
  OFFLINE = 'offline',
  DEFAULT = 'default'
}
