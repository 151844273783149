import { Environment, Profile } from '@env/environment.interface';

export const environment: Environment = {
  production: true,
  backend: '${BACKEND_ADDRESS}',
  httpBackend: 'https://${BACKEND_ADDRESS}',
  profile: '${PROFILE}' as Profile,
  clientId: '${CLIENT_ID}',
  clientSecret: '${CLIENT_SECRET}',
};

